import React, { useEffect } from 'react';
import { useAuth } from './AuthContext'; 

function TestAuthPage() {
    const { currentUser } = useAuth();
    console.error('User Info', currentUser);
    const userGroups = JSON.parse(localStorage.getItem('userGroups'));
    if (userGroups) {
      console.log('User groups from local storage', userGroups);
    } else {
      console.log('No user groups found in local storage');
    }
    //console.log('groups: ',localStorage.getItem('userGroups'));
    // useEffect(() => {
    //     console.log('User Groups:', userGroups);
    // }, [userGroups]);

    return (
      <div>
        <br></br><br></br><br></br>
        {currentUser ? (
          <>
            <p>Logged in as: {currentUser?.attributes?.email ?? ''} </p> 
            <p>User Groups: {userGroups.join(', ')}</p>
          </>
        ) : (
          <p>Not logged in</p>
        )}
      </div>
    );
}

export default TestAuthPage;
