import React, { useEffect } from 'react';

const StripePricingTable = () => {
  useEffect(() => {
    // Dynamically add the Stripe Pricing Table script
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    document.body.appendChild(script);

    // Cleanup: remove the script when the component is unmounted
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <br></br><br></br><br></br>
      <stripe-pricing-table
        pricing-table-id="prctbl_1PsdZjHuikSJIOpoJqMjtjOr"
        publishable-key="pk_live_TAd2DXiZ8mYbqJGCdOH28flV00Mw2e8FdO">
      </stripe-pricing-table>
    </div>
  );
};

export default StripePricingTable;
