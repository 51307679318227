export const GA_TRACKING_ID = "G-CB63RRZNLP";

// Initialize Google Analytics
export const initGA = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
        window.dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", GA_TRACKING_ID);
};

// Log page views
export const logPageView = (url) => {
    window.gtag("config", GA_TRACKING_ID, {
        page_path: url,
    });
};
