// src/components/auth/SignIn.js
import React, { useState } from "react";
import { Auth, Cache } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import logo from "../../assets/images/logo_tp.png";
import "../../assets/css/SignIn.css";
import { event } from "jquery";

function SignIn() {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);

    const { setCurrentUser } = useAuth();
    const { currentUser } = useAuth();
    const { setUserGroups } = useAuth();

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        await handleSignIn();
    };

    const handleSignIn = async () => {
        try {
            const user = await Auth.signIn(email, password);
            //console.log('Sign-in successful', user);
            setCurrentUser(user);

            // Get the JWT token and store it in local storage
            if (user.signInUserSession) {
                console.log("Sign-in session", user.signInUserSession);
                const jwtToken = user.signInUserSession.idToken.jwtToken;
                localStorage.setItem("jwtToken", jwtToken);
            }

            // Returns an array of groups
            const groups =
                user.signInUserSession.accessToken.payload["cognito:groups"];

            // store user groups in local storage
            if (groups) {
                console.log("User groups", groups);
                //localStorage.setItem('userGroups', JSON.stringify(groups));
                setUserGroups(groups);
            }

            //console.error('Signed in User Info', currentUser);
            navigate("/Members"); // Navigate to the members area
        } catch (error) {
            console.error("Error signing in", error);
            setError(error.message);
        }
    };

    const handleSignUp = () => {
        navigate("/signup"); // Navigate to the signup page (adjust the route if necessary)
    };

    const handlePasswordReset = () => {
        navigate("/reset-password"); // Navigate to the password reset page (create this route and page separately)
    };

    return (
        <div className="signin-container">
            <div className="signin-form">
                <form onSubmit={handleFormSubmit}>
                    <div className="signin-title">
                        <h2>Sign In</h2>
                    </div>
                    <img
                        className="signin-logo"
                        src={logo}
                        alt="rippaday.com"
                    />
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                    />
                    <button type="submit" onClick={handleSignIn}>
                        Sign in
                    </button>
                    <button
                        className="signup-button"
                        type="submit"
                        onClick={handleSignUp}
                    >
                        Sign Up
                    </button>
                    <div className="horizontal-line"></div>
                    <div
                        className="forgot-pass-div"
                        onClick={handlePasswordReset}
                    >
                        Forgot password?
                    </div>
                    {error && <div className="error-div">{error}</div>}
                </form>
            </div>
        </div>
    );
}

export default SignIn;
