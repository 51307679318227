import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth, refreshToken } from './auth/AuthContext';
import { Auth } from 'aws-amplify';
import { initGA, logPageView } from "../utils/gtag"; // Import the functions
import useDocumentTitle from "../hooks/useDocumentTitle"; // Import the custom hook

function SubscriptionValidation() {
  useDocumentTitle('Validate Subscription'); // Set the page title
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  let { userGroups } = useAuth();

  useEffect(() => {
    initGA(); // Initialize Google Analytics
    logPageView(window.location.pathname); // Log the page view
    const validateSubscription = async () => {
      try {
        // Get a valid token, refreshing if necessary
        console.log('Refreshing token SV15');
        await refreshToken();
        // userGroups = JSON.parse(localStorage.getItem('userGroups'));
        // console.log('User groups: ',userGroups);

        // // Check if the user has the 'activeSubscription' group
        // if (userGroups.includes('activeSubscription')) {
        //   console.log('User has required access');
        //   navigate('/members');
        //   return;
        // }
        const user =  await Auth.currentAuthenticatedUser({bypassCache: true});

        // Returns an array of groups
        const groups = await user.signInUserSession.accessToken.payload["cognito:groups"];
        console.log('groups sv30: ',groups);
        // if(groups){
        // // Check if the user has the 'activeSubscription' group
        //     if (groups.includes('activeSubscription')) {
        //         console.log('User has required access - groups');
        //         localStorage.setItem('userGroups', JSON.stringify(groups));
        //         setTimeout(1000);
        //         navigate('/members');
        //         return;
        //     }
        // }  
        if (groups) {
          // Check if the user has the 'activeSubscription' group
          if (groups.includes('activeSubscription')) {
            console.log('User has required access - groups');
            localStorage.setItem('userGroups', JSON.stringify(groups));
            setTimeout(() => {
              window.location.href = 'https://app.rippaday.com/Members';
            }, 1000);

            // Trigger Google Analytics event
            window.gtag('event', 'conversion_event_subscribe_paid', {
              // Add event parameters if needed
            });

            return;
          }
        }

      } catch (error) {
        console.error('Error validating subscription:', error);
      }

      // If the validation fails, wait for 2 seconds and try again
      setTimeout(validateSubscription, 2000);
    };

    validateSubscription();
  }, [navigate, userGroups]);

  const navigateToMembers = () => { // Create a function to navigate to the sign-in route
    navigate('/members'); // Use navigate function to navigate to the sign-in route
  };
  return (
    <div>
        <br></br><br></br><br></br><br></br>
      <center>
        {isLoading && <p>Validating your subscription. Please wait...</p>}
        <br></br>
        <strong>If you are not autoamtically redirected after 10 seconds, click below</strong>
        <br></br>
        <button className="subscription-button"  onClick={navigateToMembers}>Members</button>
        </center>
    </div>
  );
}

export default SubscriptionValidation;
