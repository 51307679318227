import React, { useState, useEffect } from "react";
import config from "../config/config";
import { useAuth, getValidToken } from "./auth/AuthContext";
import { loadStripe } from "@stripe/stripe-js";
import "../assets/css/Subscription.css";
import { initGA, logPageView } from "../utils/gtag"; // Import the functions
import useDocumentTitle from "../hooks/useDocumentTitle"; // Import the custom hook

const stripePromise = loadStripe(config.stripeToken);

function SubscriptionManagement() {
    useDocumentTitle('Manage Subscription'); // Set the page title
    const [subscriptions, setSubscriptions] = useState([]);
    const [token, setToken] = useState(null);
    const [lastPaymentDates, setLastPaymentDates] = useState({});
    const [prices, setPrices] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [showChangePlanModal, setShowChangePlanModal] = useState(false);

    useEffect(() => {
        initGA(); // Initialize Google Analytics
        logPageView(window.location.pathname); // Log the page view
        const fetchTokenAndSubscriptions = async () => {
            try {
                const fetchedToken = await getValidToken(); // Get the valid token
                setToken(fetchedToken); // Set the token state
                await fetchSubscriptionDetails(fetchedToken); // Fetch subscription details using the token
                await fetchPrices(); // Fetch available plans
            } catch (error) {
                console.error("Error fetching token and subscription details:", error);
            }
        };

        fetchTokenAndSubscriptions(); // Call the function to fetch token and subscription
    }, []);

    const fetchSubscriptionDetails = async (token) => {
        try {
            const response = await fetch(`${config.subscriptionUrl}`, {
                headers: {
                    authorization: `${token}`, // Set the Authorization header with the token
                },
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            console.log("Fetched subscription data: ", data);
            setSubscriptions(data.data); // Set the subscriptions state

            // Store last payment dates
            const paymentDates = {};
            data.data.forEach(subscription => {
                paymentDates[subscription.id] = subscription.latest_invoice_data ? subscription.latest_invoice_data.created : null;
            });
            setLastPaymentDates(paymentDates);
        } catch (error) {
            console.error("Error fetching subscription details", error);
        }
    };

    const fetchPrices = async () => {
        try {
            const response = await fetch(`${config.subscribeUrl}/products`);
            const data = await response.json();
            setPrices(data.prices);
        } catch (error) {
            console.error("Error fetching prices", error);
        }
    };

    const handleCheckout = async (newPlanId) => {
        try {
            const stripe = await stripePromise;

            const response = await fetch(`${config.subscribeUrl}/pay`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `${token}`,
                },
                body: JSON.stringify({ priceId: newPlanId }),
            });

            const session = await response.json();
            const result = await stripe.redirectToCheckout({
                sessionId: session.id,
            });

            if (result.error) {
                alert(result.error.message);
            }
        } catch (error) {
            console.error("Error initiating Stripe checkout", error);
        }
    };

    const updateSubscription = async (token, newPlanId) => {
        try {
            const response = await fetch(`${config.subscribeUrl}/update`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `${token}`, // Ensure token is included in request headers
                },
                body: JSON.stringify({
                    subscriptionId: subscriptions[0].id,
                    newPlanId,
                    proration_behavior: 'create_prorations', // Ensure proration behavior is included
                }),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            console.log("Updated subscription data: ", data);
            setSubscriptions([data.subscription]);
        } catch (error) {
            console.error("Error updating subscription", error);
        }
    };

    const cancelSubscription = async (token) => {
        try {
            console.log("Token", token);
            const response = await fetch(`${config.subscriptionUrl}/cancel`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `${token}`,
                },
                body: JSON.stringify({ subscriptionId: subscriptions[0].id }),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            console.log("Canceled subscription data: ", data);
            setSubscriptions([data.subscription]);
        } catch (error) {
            console.error("Error cancelling subscription", error);
        }
    };

    const reactivateSubscription = async (token) => {
        try {
            const response = await fetch(
                `${config.subscriptionUrl}/reactivate`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `${token}`, // Ensure token is included in request headers
                    },
                    body: JSON.stringify({ subscriptionId: subscriptions[0].id }),
                }
            );
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            console.log("Reactivated subscription data: ", data);
            setSubscriptions([data.subscription]);

            // Retain the last payment date from the original data
            setLastPaymentDates(prevDates => ({
                ...prevDates,
                [data.subscription.id]: prevDates[data.subscription.id] || null,
            }));
        } catch (error) {
            console.error("Error reactivating subscription", error);
        }
    };

    const formatDate = (timestamp) => {
        const date = new Date(timestamp * 1000);
        return date.toLocaleString('en-AU', { timeZone: 'Australia/Sydney' });
    };

    const openChangePlanModal = (subscriptionId) => {
        setSelectedPlan(subscriptionId);
        setShowChangePlanModal(true);
    };

    const handlePlanChange = (planId) => {
        setSelectedPlan(planId);
    };

    const handleChangePlanSubmit = () => {
        if (selectedPlan) {
            updateSubscription(token, selectedPlan); // Call updateSubscription directly
            setShowChangePlanModal(false);
        }
    };

    return (
        <div className="subscriptionContainer">
            <div className="subscriptionForm">
                <h2>Manage Your Subscription</h2>
                {subscriptions.length > 0 ? (
                    subscriptions.map(subscription => {
                        console.log("Subscription status: ", subscription.status);
                        console.log("Subscription cancel_at_period_end: ", subscription.cancel_at_period_end);
                        console.log("Subscription canceled_at: ", subscription.canceled_at);

                        const isCancellationRequested = subscription.cancel_at_period_end && subscription.canceled_at;

                        return (
                            <div key={subscription.id} className="subscription-item">
                                <p>
                                    <strong>Current Plan:</strong> {subscription.items.data[0].plan.nickname} - 
                                    ${subscription.items.data[0].plan.amount / 100} 
                                    ({subscription.items.data[0].plan.currency.toUpperCase()}) 
                                    per {subscription.items.data[0].plan.interval}
                                </p>
                                <p>
                                    <strong>Status:</strong> {isCancellationRequested ? "cancellation requested" : subscription.status}
                                </p>
                                <p>
                                    <strong>Last Payment Date:</strong> {lastPaymentDates[subscription.id] ? formatDate(lastPaymentDates[subscription.id]) : 'No invoice data available'}
                                </p>
                                {isCancellationRequested ? (
                                    <>
                                        <p><strong>Canceled At:</strong> {formatDate(subscription.canceled_at)}</p>
                                        <p><strong>Subscription End Date:</strong> {formatDate(subscription.current_period_end)}</p>
                                    </>
                                ) : (
                                    <p><strong>Next Payment Date:</strong> {formatDate(subscription.current_period_end)}</p>
                                )}
                                {subscription.status === 'active' && !isCancellationRequested ? (
                                    <>
                                        <button className="action-button" onClick={() => openChangePlanModal(subscription.id)}>
                                            Change Plan
                                        </button>
                                        <button className="cancel-button" onClick={() => cancelSubscription(token)}>
                                            Cancel Subscription
                                        </button>
                                    </>
                                ) : (
                                    <button className="action-button" onClick={() => reactivateSubscription(token)}>
                                        Reactivate Subscription
                                    </button>
                                )}
                            </div>
                        );
                    })
                ) : (
                    <p>Loading...</p>
                )}

                {showChangePlanModal && (
                    <div className="my-modal">
                        <div className="my-modal-content">
                            <h2>Select a new plan</h2>
                            <div className="subscription-options">
                                {prices
                                    .sort((a, b) => a.unit_amount - b.unit_amount)
                                    .map((price) => (
                                        <label key={price.id}>
                                            <input
                                                type="radio"
                                                name="plan"
                                                value={price.id}
                                                onChange={(e) => handlePlanChange(e.target.value)}
                                            />
                                            {`$${(price.unit_amount / 100).toFixed(2)} a ${price.recurring.interval}`}
                                        </label>
                                    ))}
                            </div>
                            <button className="action-button" onClick={handleChangePlanSubmit}>Submit</button>
                            <button className="action-button" onClick={() => setShowChangePlanModal(false)}>Close</button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default SubscriptionManagement;
