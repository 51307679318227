import React, { useEffect, useState } from "react";
import { useAuth } from "./auth/AuthContext"; // Adjust the path to point to your AuthContext file
import { Link } from "react-router-dom";
import facebook from "../assets/images/facebook.png";
import twitter from "../assets/images/twitter.png";
import instagram from "../assets/images/instagram.png";
import "../assets/css/Footer.css";

function Footer() {
    const { currentUser } = useAuth();
    const [isShowMobileFooterOption, setIsShowMobileFooterOption] = useState(false);

    const handleMobileFooter = () => {
        setIsShowMobileFooterOption((prevData) => !prevData);
    };

    const handleFooterMenuClick = () => {
        setIsShowMobileFooterOption(false);
    };

    useEffect(() => {
        setIsShowMobileFooterOption(false);
    }, []);

    return (
        <footer>
            <div className="container">
                <p>
                    <a href="https://www.facebook.com/rippaday">
                        <img
                            src={facebook}
                            height="25"
                            width="25"
                            alt="RippaDay"
                        />{" "}
                    </a>
                    <a href="https://twitter.com/rippaday">
                        <img
                            src={twitter}
                            height="24"
                            width="24"
                            alt="RippaDay"
                        />{" "}
                    </a>
                    <a href="https://www.instagram.com/rippaday">
                        <img
                            src={instagram}
                            height="25"
                            width="25"
                            alt="RippaDay"
                        />{" "}
                    </a>
                    | &copy; Rippaday.com <strong> </strong>
                    {currentUser ? (
                        <>
                            |{" "}
                            <Link to="/SubscriptionManagement">
                                {currentUser?.attributes?.email ?? ""}
                            </Link>{" "}
                            <strong> </strong>|{" "}
                            <Link to="/SignOut">Logout </Link>
                        </>
                    ) : (
                        <>
                            | <Link to="/SignIn">Login </Link>
                        </>
                    )}
                    |{" "}
                    <a href="https://freeform.rippaday.com/#contact">Contact</a>{" "}
                    | &nbsp;
                    <a href="https://rippaday.com/terms/">
                        Terms of Use
                    </a>{" "}
                    |{" "}
                    <a href="https://rippaday.com/privacy/">Privacy Policy</a>{" "}
                    |{" "}
                    <a href="https://rippaday.com/about/">
                        What is Dutch Betting?{" "}
                    </a>
                    | All information is for entertainment purposes only. Gamble
                    Responsibly.{" "}
                    <a href="http://www.gamblinghelponline.org.au">
                        www.gamblinghelponline.org.au
                    </a>
                </p>
            </div>

            <div className="mobile-footer">
                <i className="fa fa-bars" onClick={handleMobileFooter}></i>
                <ul className={isShowMobileFooterOption ? "expanded" : ""}>
                    <a href="https://rippaday.com/">
                        <li onClick={handleFooterMenuClick}>Home</li>
                    </a>
                    <a href="https://rippaday.com/">
                        <li onClick={handleFooterMenuClick}>Tools</li>
                    </a>
                    <a href="https://rippaday.com/terms/">
                        <li onClick={handleFooterMenuClick}>Terms of Use</li>
                    </a>
                    <a href="https://rippaday.com/privacy/">
                        <li onClick={handleFooterMenuClick}>Privacy Policy</li>
                    </a>
                    <a href="https://freeform.rippaday.com/#contact">
                        <li onClick={handleFooterMenuClick}>
                            Contact Us
                        </li>
                    </a>
                    {currentUser && (
                        <Link to="/SubscriptionManagement">
                            <li onClick={handleFooterMenuClick}>Manage</li>
                        </Link>
                    )}
                    <Link to={currentUser ? "/SignOut" : "/SignIn"}>
                        <li onClick={handleFooterMenuClick}>
                            {currentUser ? "Logout" : "Login"}
                        </li>
                    </Link>
                </ul>
            </div>
        </footer>
    );
}

export default React.memo(Footer);
