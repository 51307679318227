import React from "react";
import "../assets/css/Loading.css";

function Loading() {
    return (
        <div className="loading-modal">
            <div>
                <span>Loading</span>
                <div className="loader"></div>
            </div>
        </div>
    );
}

export default Loading;
