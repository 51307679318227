import React, { useState } from "react";
import "../assets/css/Drink.css";
import drinkMainLogo from "../assets/images/buy_us_beer_main_logo.png";
import drinkHadAWinLogo from "../assets/images/had_a_win.png";
import drinkCan from "../assets/images/drink-can.png";
import drink6Pack from "../assets/images/drink-6pack.png";
import drinkCarton from "../assets/images/drink-carton.png";
import drinkCanMonth from "../assets/images/drink-can-month.png";
import { loadStripe } from "@stripe/stripe-js";
import config from "../config/config";

const stripePromise = loadStripe(config.stripeToken);

export default function Drink() {
    const [isDrinkShown, setIsDrinkShown] = useState(false);

    const tonggleDrinkMenu = () => {
        setIsDrinkShown(!isDrinkShown);
    };

    const handleButtonClick = async (event) => {
        const stripe = await stripePromise;
        const mode = event.target.dataset.checkoutMode;
        const priceId = event.target.dataset.priceId;
        const items = [{ price: priceId, quantity: 1 }];

        const DOMAIN = window.location.href.replace(/[^/]*$/, "");

        stripe
            .redirectToCheckout({
                mode,
                lineItems: items,
                successUrl: `${DOMAIN}success?session_id={CHECKOUT_SESSION_ID}`,
                cancelUrl: `${DOMAIN}index.html?session_id={CHECKOUT_SESSION_ID}`,
            })
            .then((result) => {
                if (result.error) {
                    const displayError =
                        document.getElementById("error-message");
                    displayError.textContent = result.error.message;
                }
            });
    };

    return (
        <div className={`drink-container ${isDrinkShown ? "expanded" : ""} `}>
            <div
                className={`drink-categories ${
                    isDrinkShown ? "drink-categories-show" : ""
                }`}
            >
                <div className="drink">
                    <img
                        src={drinkCan}
                        alt="Drink Can"
                        data-checkout-mode="payment"
                        data-price-id="price_1I6VuEHuikSJIOpo5mSBpytp"
                        onClick={handleButtonClick}
                    />
                    Can ($5)
                </div>
                <div className="drink">
                    <img
                        src={drink6Pack}
                        alt="Drink 6 Pack"
                        data-checkout-mode="payment"
                        data-price-id="price_1I6oDWHuikSJIOpozKNyMA9X"
                        onClick={handleButtonClick}
                    />
                    6 Pack ($20)
                </div>
                <div className="drink">
                    <img
                        src={drinkCarton}
                        alt="Drink Carton"
                        data-checkout-mode="payment"
                        data-price-id="price_1I6oDzHuikSJIOpo8E7HoFNA"
                        onClick={handleButtonClick}
                    />
                    Carton ($50)
                </div>
                <div className="drink">
                    Keep 'em coming!
                    <img
                        src={drinkCanMonth}
                        alt="Drink Can"
                        data-checkout-mode="subscription"
                        data-price-id="price_1I6oENHuikSJIOpodHa1Dqde"
                        onClick={handleButtonClick}
                    />
                    $5 / month
                </div>
            </div>
            <div className="drink-main" onClick={tonggleDrinkMenu}>
                {isDrinkShown ? (
                    <img
                        src={drinkMainLogo}
                        alt="Buy us beer"
                        style={{ width: "85px" }}
                    />
                ) : (
                    <img
                        src={drinkHadAWinLogo}
                        alt="Buy us beer"
                        style={{ width: "85px" }}
                    />
                )}
            </div>
        </div>
    );
}
