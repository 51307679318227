import React from "react";
import "../assets/css/RaceModal.css";
import RaceBetTable from "./RaceBetTable";
import ButtonClose from "../assets/images/btn-close.svg";

function RaceModal({
    toggleModal = () => {},
    fetchedRaceData,
    isFixedOdds,
    betType,
    first4Data = [],
}) {
    const first4TipData =
        first4Data.find(
            (data) =>
                data.Venue &&
                data.Venue.toLowerCase() ===
                    fetchedRaceData.meeting.meetingName.toLowerCase() &&
                data.Race &&
                data.Race === fetchedRaceData.raceNumber
        ) ?? undefined;

    let first4Tips = [];

    if (first4TipData && first4TipData.tips) {
        first4Tips = first4TipData.tips.map((tip) => {
            return tip.Tip;
        });
    }

    return (
        <div className="popup">
            <div className="popup-container">
                <button className="popup-close-btn" onClick={toggleModal}>
                    <img src={ButtonClose} alt="close button" />
                </button>
                <div className="popup-header">
                    <span>{fetchedRaceData.meeting.meetingName} </span> - Race{" "}
                    {fetchedRaceData.raceNumber}
                </div>
                <div className="popup-body">
                    <RaceBetTable
                        fetchedRaceData={fetchedRaceData}
                        isFixedOdds={isFixedOdds}
                        betType={betType}
                        first4Tips={first4Tips}
                    />
                </div>
            </div>
        </div>
    );
}

export default RaceModal;
