import React from "react";
import { useTable, useExpanded } from "react-table";

function NestedDataTable({ data, columns, nestedColumns }) {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable({ columns, data }, useExpanded);

    const handleRowExpand = (event) => {
        const div = event.currentTarget;
        if (div.classList.contains("expanded")) {
            div.classList.remove("expanded");
        } else {
            div.classList.add("expanded");
        }
    };

    const isMobile = window.innerWidth <= 768;

    return (
        <table {...getTableProps()} className="myTabs">
            <thead>
                {headerGroups.map((headerGroup) => (
                    <tr
                        {...headerGroup.getHeaderGroupProps()}
                        className="table-header"
                    >
                        {headerGroup.headers.map((column) => (
                            <th
                                {...column.getHeaderProps()}
                                className={`table-cell ${column.Class ?? ""}`}
                            >
                                {column.render("Header")}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()} className="table-body">
                {rows.map((row) => {
                    prepareRow(row);
                    let resultClass = "";
                    if (row.original.Result.includes("1st")) {
                        resultClass = "win";
                    } else if (
                        row.original.Result.includes("2nd") ||
                        row.original.Result.includes("3rd")
                    ) {
                        resultClass = "place";
                    } else if (
                        row.original.Result.includes("Abandoned") ||
                        row.original.Result.includes("Abnd")
                    ) {
                        resultClass = "abnd";
                    } else if (
                        row.original.Result.includes("4th") ||
                        row.original.Result.includes("Lose")
                    ) {
                        resultClass = "lose";
                    }

                    return (
                        <React.Fragment key={row.id}>
                            <tr
                                {...row.getRowProps()}
                                className={resultClass + " table-row"}
                                onClick={(e) => {
                                    row.toggleRowExpanded();
                                    handleRowExpand(e);
                                }}
                            >
                                {row.cells.map((cell) => {
                                    if (isMobile && cell.column.id === 'Venue') {
                                        return (
                                            <td
                                                {...cell.getCellProps()}
                                                data-label={cell.column.Header}
                                                className={`${
                                                    cell.column.Class ?? "card-details"
                                                }`}
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',  // Ensure items are centered vertically
                                                    padding: '8px',
                                                    border: '1px solid #ddd'
                                                }}
                                            >
                                                <div>{cell.render("Cell")}</div>
                                                {row.original.Result !== 'Open' && (
                                                    <div style={{ marginLeft: '8px' }}>
                                                        {row.original.Result}
                                                    </div>
                                                )}
                                            </td>
                                        );
                                    }

                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                            data-label={cell.column.Header}
                                            className={`${
                                                cell.column.Class ?? "card-details"
                                            }`}
                                        >
                                            {cell.render("Cell")}
                                        </td>
                                    );
                                })}
                            </tr>
                            {row.isExpanded ? (
                                <tr className="expanded-row">
                                    <td
                                        colSpan={columns.length}
                                        className="expanded-cell"
                                    >
                                        <table className="myTabsDetail">
                                            <thead className="nested-header">
                                                <tr>
                                                    {nestedColumns.map(
                                                        (column) => (
                                                            <th
                                                                key={
                                                                    column.accessor
                                                                }
                                                                className={`nested-header-cell ${
                                                                    column.Class ?? ""
                                                                }`}
                                                            >
                                                                {column.Header}
                                                            </th>
                                                        )
                                                    )}
                                                </tr>
                                            </thead>
                                            <tbody className="nested-body">
                                                {row.original.tips.map(
                                                    (tip, i) => (
                                                        <tr
                                                            key={i}
                                                            className="nested-row"
                                                        >
                                                            {nestedColumns.map(
                                                                (column) => (
                                                                    <td
                                                                        key={
                                                                            column.accessor
                                                                        }
                                                                        className={`nested-cell ${
                                                                            column.Class ?? ""
                                                                        }`}
                                                                    >
                                                                        {
                                                                            tip[
                                                                                column
                                                                                    .accessor
                                                                            ]
                                                                        }
                                                                    </td>
                                                                )
                                                            )}
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            ) : null}
                        </React.Fragment>
                    );
                })}
            </tbody>
        </table>
    );
}

export default NestedDataTable;
