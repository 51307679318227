import React, { useState, useEffect } from "react";
import Modal from 'react-modal';
import "../assets/css/Merch.css";
import { useNavigate } from "react-router-dom"; // Import useNavigate instead of useHistory
import config from "../config/config";
import { initGA, logPageView } from "../utils/gtag"; // Import the functions
import useDocumentTitle from "../hooks/useDocumentTitle"; // Import the custom hook

Modal.setAppElement('#root'); // This line is necessary for accessibility reasons

function Merch() {
    useDocumentTitle('RippaDay - Merch'); // Set the page title
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedVariant, setSelectedVariant] = useState({});
    const [modalIsOpen, setModalIsOpen] = useState(false);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await fetch(`${config.apiUrl}/merch/products`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                
                const data = await response.json();
                if (data && data.data) {
                    setProducts(data.data.map(product => {
                        const defaultVariant = product.variants.find(variant => variant.is_default) || product.variants.find(variant => variant.is_enabled) || product.variants[0];
                        return {
                            ...product,
                            selectedImage: product.images.find(image => image.variant_ids.includes(defaultVariant.id))?.src || product.images[0]?.src,
                            selectedVariantId: defaultVariant.id,
                            outOfStock: !defaultVariant.is_available
                        };
                    }));
                } else {
                    console.error('No products found in response:', data);
                }
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, []);

    const openModal = (product) => {
        setSelectedProduct(product);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setSelectedProduct(null);
    };

    const handleVariantChange = (productId, optionName, valueId) => {
        setSelectedVariant(prev => ({
            ...prev,
            [productId]: {
                ...prev[productId],
                [optionName]: valueId,
            }
        }));

        const updatedProducts = products.map(product => {
            if (product.id === productId) {
                const selectedVariant = product.variants.find(variant => 
                    variant.options.includes(parseInt(valueId))
                );
                const selectedImage = product.images.find(image => image.variant_ids.includes(selectedVariant.id))?.src;
                return { 
                    ...product, 
                    selectedImage, 
                    selectedVariantId: selectedVariant.id,
                    outOfStock: !selectedVariant.is_available
                };
            }
            return product;
        });
        setProducts(updatedProducts);
    };

    const addToCart = (product) => {
        const variant = selectedVariant[product.id];
        if (variant) {
            console.log('Adding to cart:', product, variant);
            // Handle adding the product to the cart here
        } else {
            alert('Please select size and color');
        }
    };

    return (
        <div className="merch-container">
            {products.length > 0 ? (
                products.map((product) => (
                    <div key={product.id} className="merch-item">
                        <div className="merch-left">
                            <img 
                                src={product.selectedImage} 
                                alt={product.title} 
                                onClick={() => openModal(product)}
                                className="merch-image"
                            />
                            <div className="merch-options">
                                {product.options.map(option => {
                                    // Filter out only the enabled variants for this option (e.g., colors)
                                    const enabledVariants = product.variants.filter(variant => variant.is_enabled);
                                    const enabledValues = option.values.filter(value =>
                                        enabledVariants.some(variant => variant.options.includes(value.id))
                                    );

                                    return (
                                        <div key={option.name} className="merch-option">
                                            <label>{option.name}:</label>
                                            <select 
                                                onChange={(e) => handleVariantChange(product.id, option.name, e.target.value)}
                                                value={selectedVariant[product.id]?.[option.name] || product.selectedVariantId}
                                            >
                                                {enabledValues.map(value => (
                                                    <option key={value.id} value={value.id}>{value.title}</option>
                                                ))}
                                            </select>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="merch-header">
                            <h2>{product.title}</h2>
                        </div>
                        <div className="merch-right">
                            <p dangerouslySetInnerHTML={{ __html: product.description }} />
                            {product.outOfStock ? (
                                <p className="out-of-stock">Out of Stock</p>
                            ) : (
                                <button className="merch-button" onClick={() => addToCart(product)}>Add to Cart</button>
                            )}
                        </div>
                    </div>
                ))
            ) : (
                <p>No products available.</p>
            )}

            {selectedProduct && (
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Product Images"
                >
                    <h2>{selectedProduct.title}</h2>
                    <div className="modal-images">
                        {selectedProduct.images.filter(image => 
                            image.variant_ids.includes(selectedProduct.selectedVariantId)
                        ).map((image, index) => (
                            <img key={index} src={image.src} alt={selectedProduct.title} style={{ width: '100%' }} />
                        ))}
                    </div>
                    <button className="merch-button"  onClick={closeModal}>Close</button>
                </Modal>
            )}
        </div>
    );
}

export default Merch;