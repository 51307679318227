import React, { useState } from "react";
import { Auth } from "aws-amplify";
import "../../assets/css/SignIn.css";

function ResetPassword() {
    const [step, setStep] = useState(1);
    const [email, setEmail] = useState("");
    const [code, setCode] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);

    const handleSendCode = async () => {
        try {
            await Auth.forgotPassword(email);
            setMessage("Confirmation code sent to your email.");
            setStep(2);
        } catch (error) {
            console.error("Error sending confirmation code", error);
            setError(error.message);
        }
    };

    const handleResetPassword = async () => {
        try {
            await Auth.forgotPasswordSubmit(email, code, newPassword);
            setMessage(
                "Password reset successful. You can now sign in with your new password."
            );
            setStep(3);
        } catch (error) {
            console.error("Error resetting password", error);
            setError(error.message);
        }
    };

    return (
        <div className="signin-container">
            <div className="signin-form">
                <div className="signin-title">
                    <h2>Reset Password</h2>
                </div>

                {step === 1 && (
                    <>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                        />
                        <button
                            className="signup-button"
                            type="submit"
                            onClick={handleSendCode}
                        >
                            Send Confirmation Code
                        </button>
                    </>
                )}

                {step === 2 && (
                    <>
                        <input
                            type="text"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                            placeholder="Confirmation Code"
                        />
                        <input
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            placeholder="New Password"
                        />
                        <button
                            className="signup-button"
                            onClick={handleResetPassword}
                        >
                            Reset Password
                        </button>
                    </>
                )}

                {step === 3 && (
                    <>
                        <p>{message}</p>
                    </>
                )}

                {error && <div className="error-div">{error}</div>}
                {message && <div className="message-div">{message}</div>}
            </div>
        </div>
    );
}

export default ResetPassword;
