import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { loadStripe } from "@stripe/stripe-js"; // You'll need to install stripe using npm install @stripe/stripe-js
import { useTable } from "react-table";
import { initGA, logPageView } from "../utils/gtag"; // Import the functions
import { useAuth, getValidToken } from "./auth/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";
import DataTable from "./DataTable";
import config from "../config/config";
import Loading from "./Loading";
import useDocumentTitle from "../hooks/useDocumentTitle"; // Import the custom hook
import { Helmet } from "react-helmet";
import { convertTo24Hour } from "../utils/helper";

const stripePromise = loadStripe(config.stripeToken);

const getSydneyDate = () => moment().tz("Australia/Sydney");

function Members() {
    useDocumentTitle("RippaDay - Members"); // Set the page title
    const [betrecData, setBetrecData] = useState([]);
    const [bestbetsData, setBestbetsData] = useState([]);
    const [twowayData, setTwowayData] = useState([]);
    const { currentUser } = useAuth();
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const [token, setToken] = useState(null); // Initialize token state
    const [isLoading, setIsLoading] = useState(true);

    // combined race data
    const [originalRaceData, setOriginalRaceData] = useState([]);
    const [displayedRaceData, setDisplayRaceData] = useState([]);
    const [venueList, setVenueList] = useState([]);
    const [selectedVenue, setSelectedVenue] = useState("All");

    // members tip
    const [isTwoWayChecked, setIsTwoWayChecked] = useState(true);
    const [isRatedChecked, setIsRatedChecked] = useState(true);
    const [isBestBetChecked, setIsBestBetChecked] = useState(true);

    const getInitialDate = () => {
        const hash = window.location.hash.slice(1);
        if (hash) {
            const parsedDate = moment(hash, "YYYY-MM-DD", true);
            if (parsedDate.isValid()) {
                return parsedDate.toDate();
            }
        }
        return getSydneyDate().toDate();
    };

    const [currentDate, setCurrentDate] = useState(getInitialDate());

    const isToday =
        currentDate.toDateString() === getSydneyDate().toDate().toDateString();

    const updateRaceData = (venue, bestRecData, ratedData, bestBetData) => {
        const tempRaceData = originalRaceData.filter((item) => {
            if (item.Venue !== venue && venue !== "All") {
                return false;
            }

            if (bestRecData && item.Type.includes("Slag")) {
                return true;
            } else if (ratedData && item.Type.includes("Rated")) {
                return true;
            } else if (bestBetData && item.Type.includes("Best")) {
                return true;
            }

            return false;
        });

        setDisplayRaceData(tempRaceData);
    };

    const handleVenueChange = (event) => {
        setSelectedVenue(event.target.value);
        updateRaceData(
            event.target.value,
            isTwoWayChecked,
            isRatedChecked,
            isBestBetChecked
        );
    };

    const handleTwoWayDataChange = () => {
        setIsTwoWayChecked((prevState) => {
            updateRaceData(
                selectedVenue,
                !prevState,
                isRatedChecked,
                isBestBetChecked
            );

            return !prevState;
        });
    };

    const handleRatedDataChange = () => {
        setIsRatedChecked((prevState) => {
            updateRaceData(
                selectedVenue,
                isTwoWayChecked,
                !prevState,
                isBestBetChecked
            );

            return !prevState;
        });
    };

    const handleBestBetDataChange = () => {
        setIsBestBetChecked((prevState) => {
            updateRaceData(
                selectedVenue,
                isTwoWayChecked,
                isRatedChecked,
                !prevState
            );

            return !prevState;
        });
    };

    useEffect(() => {
        initGA(); // Initialize Google Analytics
        logPageView(window.location.pathname); // Log the page view
        const fetchToken = async () => {
            try {
                const fetchedToken = await getValidToken(); // Get the valid token
                setToken(fetchedToken); // Set the token state
            } catch (error) {
                console.error("Error fetching token:", error);
                // Redirect to the login screen
                window.location.href = "/signin";
            }
        };

        fetchToken(); // Call the fetchToken function
    }, []);

    useEffect(() => {
        if (token) {
            // Only proceed if the token is available
            const fetchRaceData = async (date) => {
                try {
                    setIsLoading(true);
                    setOriginalRaceData([]); // Clear existing data
                    setDisplayRaceData([]); // Clear displayed data
                    const formattedDate = moment(date)
                        .tz("Australia/Sydney")
                        .format("YYYYMMDD");
    
                    const betrecResponse = await fetch(
                        `${config.membersUrl}/R/${formattedDate}/betrec`,
                        {
                            headers: {
                                authorization: `${token}`, // Assuming Session contains the JWT token
                            },
                        }
                    );
                    const bestbetsResponse = await fetch(
                        `${config.membersUrl}/R/${formattedDate}/bestbets`,
                        {
                            headers: {
                                authorization: `${token}`, // Assuming Session contains the JWT token
                            },
                        }
                    );
                    const twowayResponse = await fetch(
                        `${config.membersUrl}/R/${formattedDate}/2way`,
                        {
                            headers: {
                                authorization: `${token}`, // Assuming Session contains the JWT token
                            },
                        }
                    );
    
                    const betrecData = await betrecResponse.json();
                    const bestbetsData = await bestbetsResponse.json();
                    const twowayData = await twowayResponse.json();
    
                    // Preprocess data to replace 0 with '-'
                    const preprocessData = (data) =>
                        data.map((item) => ({
                            ...item,
                            "Win $": item["Win $"] === 0 ? "-" : item["Win $"],
                            "Place $": item["Place $"] === 0 ? "-" : item["Place $"],
                        }));
    
                    setIsLoading(false);
                    if (betrecData && Array.isArray(betrecData)) {
                        const ratedResponseData = preprocessData(
                            betrecData.map((item) => ({
                                ...item,
                                Type: ["Rated"],
                            }))
                        );
    
                        setOriginalRaceData((prevDataArray) => {
                            const updatedDataArray = [...ratedResponseData];
    
                            bestbetsData.forEach((newData) => {
                                const existingIndex =
                                    updatedDataArray.findIndex(
                                        (item) => item.Horse === newData.Horse
                                    );
                                if (existingIndex !== -1) {
                                    // Update existing item
                                    if (
                                        !updatedDataArray[existingIndex]["Type"].includes(
                                            "Best"
                                        )
                                    ) {
                                        updatedDataArray[existingIndex]["Type"].push(
                                            "Best"
                                        );
                                    }
                                } else {
                                    // Append new item
                                    updatedDataArray.push({
                                        ...newData,
                                        Type: ["Best"],
                                    });
                                }
                            });
    
                            twowayData.forEach((newData) => {
                                const existingIndex =
                                    updatedDataArray.findIndex(
                                        (item) => item.Horse === newData.Horse
                                    );
                                if (existingIndex !== -1) {
                                    // Update existing item
                                    if (
                                        !updatedDataArray[existingIndex]["Type"].includes(
                                            "Slag"
                                        )
                                    ) {
                                        updatedDataArray[existingIndex]["Type"].push(
                                            "Slag"
                                        );
                                    }
                                } else {
                                    // Append new item
                                    updatedDataArray.push({
                                        ...newData,
                                        Type: ["Slag"],
                                    });
                                }
                            });
    
                            const uniqueVenues = [
                                ...Array.from(
                                    new Set(
                                        updatedDataArray.map((item) => item.Venue)
                                    )
                                ),
                            ];
    
                            setVenueList(uniqueVenues); // Add "All" at the beginning
                            const sortedData = updatedDataArray.sort((a, b) => {
                                const timeA = convertTo24Hour(a.Time);
                                const timeB = convertTo24Hour(b.Time);
                                return timeA.localeCompare(timeB);
                            });
    
                            setDisplayRaceData(sortedData);
                            return sortedData;
                        });
                    } else {
                        console.log("no data found");
                    }
                } catch (error) {
                    console.error("Error fetching race data:", error);
                    navigate("/signin"); // Redirect to sign-in page
                }
            };
    
            fetchRaceData(currentDate);
        }
    }, [token, currentDate]); // Add token and currentDate as dependencies to this useEffect
    

    const handlePreviousDate = () => {
        const newDate = moment(currentDate).subtract(1, "days").toDate();
        setCurrentDate(newDate);
        setSelectedVenue("All"); // Reset venue selection
        setIsTwoWayChecked(true); // Reset filter
        setIsRatedChecked(true); // Reset filter
        setIsBestBetChecked(true); // Reset filter
        navigate(
            `#${moment(newDate).tz("Australia/Sydney").format("YYYY-MM-DD")}`
        );
    };

    const handleNextDate = () => {
        const newDate = moment(currentDate).add(1, "days").toDate();
        if (
            moment(newDate)
                .tz("Australia/Sydney")
                .isSameOrBefore(getSydneyDate())
        ) {
            setCurrentDate(newDate);
            setSelectedVenue("All"); // Reset venue selection
            setIsTwoWayChecked(true); // Reset filter
            setIsRatedChecked(true); // Reset filter
            setIsBestBetChecked(true); // Reset filter
            navigate(
                `#${moment(newDate)
                    .tz("Australia/Sydney")
                    .format("YYYY-MM-DD")}`
            );
        }
    };

    useEffect(() => {
        console.log("Current Date Updated:", currentDate);
    }, [currentDate]);

    const columns = React.useMemo(
        () => [
            {
                Header: "Time",
                accessor: "Time",
                Class: "card-time",
            },
            {
                Header: "Venue",
                accessor: "Venue",
                Class: "card-venue",
            },
            {
                Header: "Race",
                accessor: "Race",
                Class: "card-race",
            },
            {
                Header: "Tip",
                accessor: "Tip",
                Class: "card-tip",
            },
            {
                Header: "Horse",
                accessor: "Horse",
                Class: "card-horse",
            },
            {
                Header: "Result",
                accessor: "Result",
            },
            {
                Header: "Win $",
                accessor: "Win $",
                Class: "card-win",
            },
            {
                Header: "Place $",
                accessor: "Place $",
                Class: "card-place",
            },
            {
                Header: "Runners",
                accessor: "Runners",
            },
            {
                Header: "Distance",
                accessor: "Distance",
            },
            {
                Header: "Type",
                accessor: "Type",
                Cell: ({ value }) => (
                    <div>
                        {value.map((type, index) => (
                            <span
                                key={index}
                                className={`badge ${type.toLowerCase()}`}
                            >
                                {type}
                            </span>
                        ))}
                    </div>
                ),
                Class: "card-type",
            },
        ],
        []
    );

    const handleButtonClick = async (event) => {
        const stripe = await stripePromise;
        const mode = event.target.dataset.checkoutMode;
        const priceId = event.target.dataset.priceId;
        const items = [{ price: priceId, quantity: 1 }];

        const DOMAIN = window.location.href.replace(/[^/]*$/, "");

        stripe
            .redirectToCheckout({
                mode,
                lineItems: items,
                successUrl: `${DOMAIN}success?session_id={CHECKOUT_SESSION_ID}`,
                cancelUrl: `${DOMAIN}index.html?session_id={CHECKOUT_SESSION_ID}`,
            })
            .then((result) => {
                if (result.error) {
                    const displayError =
                        document.getElementById("error-message");
                    displayError.textContent = result.error.message;
                }
            });
    };

    return (
        <div className="container">
            {isLoading ? (
                <Loading />
            ) : (
                <section
                    className="ctext section-body"
                    id="header1-0"
                    data-rv-view="0"
                >
                    <Helmet>
                        <meta
                            name="description"
                            content="Exclusive members tips. Best Bets. Sure Thing"
                        />
                        <meta
                            name="keywords"
                            content="Exclusive members tips. Best Bets. Sure Thing for venues including Eagle Farm, Caulfield, Flemington, Moonee Valley, Rosehill and Randwick"
                        />
                    </Helmet>
                    <div className="filter-container">
                        <h1 className="table-header">
                            {isToday
                                ? "Today's Members Tips  "
                                : `Results for ${moment(currentDate)
                                      .tz("Australia/Sydney")
                                      .format("DD/MM/YYYY")}  `}
                            <button
                                id="prev-date-button"
                                onClick={handlePreviousDate}
                                className="nav-arrow"
                            >
                                &#8592;
                            </button>
                            <button
                                id="next-date-button"
                                onClick={handleNextDate}
                                className="nav-arrow"
                                disabled={isToday}
                            >
                                &#8594;
                            </button>
                        </h1>
                        <div className="filter-section">
                            <select
                                className="filter-dropdown"
                                onChange={handleVenueChange}
                                value={selectedVenue}
                            >
                                <option value="All">All</option>
                                {venueList.map((venue) => {
                                    return (
                                        <option key={venue} value={venue}>
                                            {venue}
                                        </option>
                                    );
                                })}
                            </select>
                            <div className="filter-group">
                                <div className="filter-checkbox">
                                    <label className="switch-label two-way">
                                        Slag
                                    </label>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            checked={isTwoWayChecked}
                                            onChange={handleTwoWayDataChange}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="filter-checkbox">
                                    <label className="switch-label rated">
                                        Rated
                                    </label>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            checked={isRatedChecked}
                                            onChange={handleRatedDataChange}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="filter-checkbox">
                                    <label className="switch-label best-bet">
                                        Best Bets
                                    </label>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            checked={isBestBetChecked}
                                            onChange={handleBestBetDataChange}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mbr-table-cell align-content-center ctext">
                        <div className="container">
                            <div>
                                <DataTable
                                    data={displayedRaceData}
                                    columns={columns}
                                />
                            </div>
                        </div>
                    </div>
                    <div id="error-message"></div>
                    <div className="modal"></div>
                </section>
            )}
        </div>
    );
}

export default React.memo(Members);
