// src/components/auth/SignOut.js
import React from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import "../../assets/css/SignIn.css";

function SignOut() {
    const { setCurrentUser, setUserGroups } = useAuth();
    const navigate = useNavigate();
    const handleSignOut = async () => {
        try {
            await Auth.signOut();
            console.log("Sign-out successful");
            setCurrentUser(null);
            setUserGroups(null);
            // Clear all local storage data
            localStorage.clear();
            localStorage.removeItem("currentUser");
            navigate("/"); // Navigate to home page
        } catch (error) {
            console.error("Error signing out", error);
            navigate("/"); // Navigate to home page
        }
    };

    return (
        <div className="signin-container">
            <div className="signin-form">
                <div className="signout">
                    <i class="fas fa-user"></i>
                    <button onClick={handleSignOut}>Sign Out</button>
                </div>
            </div>
        </div>
    );
}

export default SignOut;
