import React, { useEffect, useState } from "react";
import "../assets/css/RaceModal.css";
import { getDutchOdds, getEachWayOdds } from "../utils/helper";
import config from "../config/config";

function RaceBetTable({
    fetchedRaceData,
    isFixedOdds,
    betType,
    first4Tips = [],
}) {
    const [betPlaced, setBetPlaced] = useState(100);
    const [runnerData, setRunnerData] = useState(
        fetchedRaceData.runners.map((runner) => {
            const overAllRating = fetchedRaceData.ratings.find(
                (rating) => rating.ratingType.toLowerCase() === "overall"
            );

            const selectedTips =
                first4Tips.length > 0
                    ? first4Tips
                    : overAllRating && overAllRating.ratingRunnerNumbers
                    ? overAllRating.ratingRunnerNumbers
                    : [];
            return {
                number: runner.runnerNumber,
                name: runner.runnerName,
                odds: isFixedOdds
                    ? parseFloat(runner.fixedOdds.returnWin)
                    : parseFloat(runner.parimutuel.returnWin),
                placeOdds: isFixedOdds
                    ? parseFloat(runner.fixedOdds.returnPlace)
                    : parseFloat(runner.parimutuel.returnPlace),
                choose: selectedTips.includes(runner.runnerNumber),
                allow: runner.fixedOdds.allowPlace,
                betAmount: 0,
                proft: 0,
                profitPercentage: 0,
                profitAmount: 0,
                placeBet: 0,
                winBet: 0,
                placeReturn: 0,
                winReturn: 0,
                placeProf: 0,
                meetingCode: fetchedRaceData.meeting.sellCode.meetingCode,
                scheduledType: fetchedRaceData.meeting.sellCode.scheduledType,
                raceNumber: fetchedRaceData.raceNumber,
            };
        })
    );

    useEffect(() => {
        if (betType === "D") {
            setRunnerData((preRunnderData) =>
                getDutchOdds(preRunnderData, betPlaced)
            );
        } else if (betType === "E") {
            setRunnerData((preRunnderData) =>
                getEachWayOdds(preRunnderData, betPlaced)
            );
        }
    }, [betPlaced, betType]);

    const handleBetAmountChanged = (e) => {
        setBetPlaced(parseInt(e.target.value));
    };

    const handleChooseEvent = (e, number) => {
        const tempRunnerData = runnerData.map((runner) => {
            if (runner.number === number) {
                runner.choose = e.target.checked;
            }
            return runner;
        });

        if (betType === "D") {
            setRunnerData(getDutchOdds(tempRunnerData, betPlaced));
        } else if (betType === "E") {
            setRunnerData(getEachWayOdds(tempRunnerData, betPlaced));
        }
    };

    const handleSlideEvent = (e, number) => {
        const tempRunnerData = runnerData.map((runner) => {
            if (runner.number === number) {
                runner.profitPercentage = parseInt(e.target.value);
                runner.profitAmount = parseInt(
                    (runner.placeOdds - 1) * runner.profitPercentage
                );
            }
            return runner;
        });
        if (betType === "D") {
            setRunnerData(getDutchOdds(tempRunnerData, betPlaced));
        } else if (betType === "E") {
            setRunnerData(getEachWayOdds(tempRunnerData, betPlaced));
        }
    };

    const handleProfitAmountChange = (e, number) => {
        const tempRunnerData = runnerData.map((runner) => {
            if (runner.number === number) {
                runner.profitAmount = parseInt(e.target.value);
                runner.profitPercentage = parseInt(
                    runner.profitAmount / (runner.placeOdds - 1)
                );
            }
            return runner;
        });
        if (betType === "D") {
            setRunnerData(getDutchOdds(tempRunnerData, betPlaced));
        } else if (betType === "E") {
            setRunnerData(getEachWayOdds(tempRunnerData, betPlaced));
        }
    };

    const handlePlaceBetEvent = (e) => {
        let betStr = "";

        runnerData.forEach((runner) => {
            if (runner.choose) {
                const betAmount = Math.round(parseFloat(runner.betAmount))
                    .toFixed(1)
                    .toString()
                    .padStart(7, 0);
                betStr +=
                    runner.meetingCode +
                    runner.scheduledType +
                    "-" +
                    runner.raceNumber +
                    "-WP-" +
                    betAmount +
                    "-00000.0/" +
                    runner.number +
                    "/" +
                    "\n";
            }
        });

        navigator.clipboard.writeText(betStr).then(() => {
            const betTabUrl = config.betTabUrl;
            window.open(betTabUrl, "_blank");
        });
    };

    return (
        <div className="bet-container">
            <div className="bet-amount">
                <label>Total Bet:</label>
                <input
                    type="number"
                    value={betPlaced}
                    onChange={handleBetAmountChanged}
                />
                <span>AUD</span>
                <button onClick={handlePlaceBetEvent}>Place Bet</button>
            </div>
            <div className="bet-table">
                {betType === "D" ? (
                    <table className="myTabs race-details">
                        <thead>
                            <tr>
                                <th className="hidden">Number</th>
                                <th>Horse</th>
                                <th>Odd</th>
                                <th>Choose</th>
                                <th>Bet Amount</th>
                                <th>Profit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {runnerData.map((runner, index) => {
                                return (
                                    <tr key={runner.number}>
                                        <td className="hidden">
                                            {runner.number}
                                        </td>
                                        <td className="card-name">
                                            {runner.name}
                                        </td>
                                        <td data-label="Odd">{runner.odds}</td>
                                        <td data-label="Choose">
                                            <input
                                                type="checkbox"
                                                onChange={(e) => {
                                                    handleChooseEvent(
                                                        e,
                                                        runner.number
                                                    );
                                                }}
                                                checked={
                                                    runner.choose &&
                                                    runner.allow
                                                }
                                                disabled={!runner.allow}
                                            />
                                        </td>
                                        <td data-label="Bet Amount">
                                            {runner.betAmount}
                                        </td>
                                        <td data-label="Profit">
                                            {runner.profit}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                ) : (
                    <table className="myTabs  race-details">
                        <thead>
                            <tr>
                                <th className="hidden">Number</th>
                                <th>Horse</th>
                                <th>Win Odds</th>
                                <th>Place Odds</th>
                                <th>Choose</th>
                                <th className="slider-header">Profit %</th>
                                <th>Place Bet</th>
                                <th>Win Bet</th>
                                <th>Place Return</th>
                                <th>Win Return</th>
                                <th>Place Prof.</th>
                            </tr>
                        </thead>
                        <tbody>
                            {runnerData.map((runner, index) => {
                                return (
                                    <tr key={runner.number}>
                                        <td data-label="Number" class="hidden">
                                            {runner.number}
                                        </td>
                                        <td className="card-name">
                                            {runner.name}
                                        </td>
                                        <td data-label="Win Odds">
                                            {runner.odds}
                                        </td>
                                        <td data-label="Place Odds">
                                            {runner.placeOdds}
                                        </td>
                                        <td data-label="Choose">
                                            <input
                                                type="checkbox"
                                                onChange={(e) => {
                                                    handleChooseEvent(
                                                        e,
                                                        runner.number
                                                    );
                                                }}
                                                checked={
                                                    runner.choose &&
                                                    runner.allow
                                                }
                                                disabled={!runner.allow}
                                            />
                                        </td>
                                        <td data-label="Profit %">
                                            <input
                                                type="range"
                                                min="0"
                                                max="100"
                                                range="0.1"
                                                value={runner.profitPercentage}
                                                className="profit-range"
                                                onChange={(e) => {
                                                    handleSlideEvent(
                                                        e,
                                                        runner.number
                                                    );
                                                }}
                                                disabled={!runner.allow}
                                            />
                                            <input
                                                type="number"
                                                className="profit-range-amount"
                                                value={runner.profitAmount}
                                                onChange={(e) => {
                                                    handleProfitAmountChange(
                                                        e,
                                                        runner.number
                                                    );
                                                }}
                                                disabled={!runner.allow}
                                            />
                                        </td>
                                        <td data-label="Place Bet">
                                            {runner.placeBet}
                                        </td>
                                        <td data-label="Win Bet">
                                            {runner.winBet}
                                        </td>
                                        <td data-label="Place Return">
                                            {runner.placeReturn}
                                        </td>
                                        <td data-label="Win Retrun">
                                            {runner.winReturn}
                                        </td>
                                        <td data-label="Place Prof.">
                                            {runner.placeProf}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
}

export default RaceBetTable;
