// convert to 24hour
export const convertTo24Hour = (timeString) => {
    const [time, modifier] = timeString.split(" ");
    let [hours, minutes] = time.split(":");

    if (hours === "12") {
        hours = "00";
    }

    if (modifier === "PM") {
        hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
};

// get current date
export const getCurrentDate = () => {
    const date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    return `${year}-${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}`;
};

// get fixed odds
export const getDutchOdds = (raceData, betPlaced) => {
    const maximumOdds = parseFloat(
        raceData.reduce(
            (max, obj) => (obj.choose ? Math.max(max, obj.odds) : max),
            -Infinity
        )
    );

    raceData = raceData.map((race) => {
        const oddConstant = race.choose
            ? parseFloat((maximumOdds / race.odds).toFixed(2))
            : 0;
        return {
            ...race,
            oddConstant,
        };
    });

    const betConstant =
        parseFloat(betPlaced) /
        parseFloat(
            raceData.reduce(
                (total, obj) =>
                    parseFloat(total) +
                    (obj.choose ? parseFloat(obj.oddConstant) : 0),
                0
            )
        );

    raceData = raceData.map((race) => {
        const betAmount = race.choose
            ? (race.oddConstant * betConstant).toFixed(2)
            : 0;
        const profit = race.choose
            ? (betAmount * race.odds - parseFloat(betPlaced)).toFixed(2)
            : 0;

        return {
            ...race,
            betAmount,
            profit,
        };
    });

    return raceData;
};

export const getEachWayOdds = (raceData, betPlaced) => {
    raceData = raceData.map((race) => {
        race.placeBet = parseFloat(
            (betPlaced / race.placeOdds) * (1 + race.profitAmount / 100)
        ).toFixed(2);

        race.winBet = (betPlaced - race.placeBet).toFixed(2);
        race.placeReturn = (race.placeOdds * race.placeBet).toFixed(2);
        race.winReturn = (race.odds * race.winBet).toFixed(2);
        race.placeProf = (race.placeReturn - betPlaced).toFixed(2);

        return {
            ...race,
        };
    });

    return raceData;
};
