// src/components/auth/SignUp.js
import React, { useState } from "react";
import { useAuth } from "./AuthContext";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo_tp.png";
import "../../assets/css/SignIn.css";

function SignUp() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmationCode, setConfirmationCode] = useState("");
    const [isConfirmation, setIsConfirmation] = useState(false);
    const { setCurrentUser } = useAuth();
    const [su_errorMessage, setSUErrorMessage] = useState("");
    const navigate = useNavigate();

    const handleSignUp = async () => {
        try {
            const data = await Auth.signUp({
                username: email,
                password,
            });
            console.log(data);
            setIsConfirmation(true);
        } catch (error) {
            console.error("Error signing up", error);
            // Extract and set the error message
            const emessage =
                error.message || "An error occurred during sign up";
            setSUErrorMessage(emessage);
        }
    };

    const handleConfirmSignUp = async () => {
        try {
            await Auth.confirmSignUp(email, confirmationCode);
            console.log("Sign up confirmed");
            // Log the user in automatically
            try {
                const user = await Auth.signIn(email, password);
                console.log("User logged in");
                setCurrentUser(user);
                navigate("/subscription"); // Navigate to the subscription route
            } catch (error) {
                console.error("Error logging in", error);
            }
        } catch (error) {
            console.error("Error confirming sign up", error);
        }
    };

    return (
        <div className="signin-container">
            <div className="signin-form">
                <div className="signin-title">
                    <h2>Sign Up</h2>
                </div>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    disabled={isConfirmation ? "disabled" : ""}
                />
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    disabled={isConfirmation ? "disabled" : ""}
                />
                {!isConfirmation && (
                    <>
                        <button
                            className="signup-button"
                            type="submit"
                            onClick={handleSignUp}
                        >
                            Sign Up
                        </button>
                    </>
                )}

                {isConfirmation && (
                    <>
                        <input
                            type="text"
                            value={confirmationCode}
                            onChange={(e) =>
                                setConfirmationCode(e.target.value)
                            }
                            placeholder="Confirmation Code"
                        />
                        <button
                            className="signup-button"
                            onClick={handleConfirmSignUp}
                        >
                            Confirm
                        </button>{" "}
                    </>
                )}

                {su_errorMessage && (
                    <div className="error-div">{su_errorMessage}</div>
                )}
            </div>
        </div>
    );
}

export default SignUp;
