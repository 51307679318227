// src/components/Home.js
// src/components/Members.js
import React, { useEffect, useState } from "react";
import { initGA, logPageView } from "../utils/gtag"; // Import the functions
import { getCurrentDate } from "../utils/helper";
import DataTable from "./DataTable";
import config from "../config/config";
import Loading from "./Loading";
import RaceModal from "./RaceModal";
import { useAuth, getValidToken } from "./auth/AuthContext";

function Tools() {
    const [raceData, setRaceData] = useState({ original: [], display: [] });

    // tools filter
    const localRegionList = [
        "nsw",
        "vic",
        "qld",
        "wa",
        "sa",
        "tas",
        "act",
        "nzl",
    ];

    const [raceType, setRaceType] = useState("R");
    const [localRegion, setLocalRegion] = useState(true);
    const [globalRegion, setGlobalRegion] = useState(false);
    const [betType, setBetType] = useState("D");
    const [isFixedOdds, setIsFixedOdds] = useState(true);
    const [token, setToken] = useState(null); // Initialize token state
    const [first4Data, setFirst4Data] = useState([]);

    // race Data
    const [fetchedRaceData, setFetchedRaceData] = useState([]);

    // race betting pop up
    const [openRaceDetails, setOpenRaceDetails] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const toggleModal = () => {
        setOpenRaceDetails((prevData) => {
            if (!prevData) {
                document.body.classList.add("popup-open");
            } else {
                document.body.classList.remove("popup-open");
            }

            return !prevData;
        });
    };

    // handle tools filter
    // Race Type => R - thoroughbred,G - greyhound, H - Harness
    // Region => Local region / Global Region

    const filterRaceData = (
        selectedRaceType,
        showLocalRegion,
        showGlobalRegion,
        originalRaceData
    ) => {
        const displayRaceData = originalRaceData.filter((race) => {
            // filter race type
            if (selectedRaceType !== race.RaceType) {
                return false;
            }

            // filter local or global region
            if (showLocalRegion && showGlobalRegion) {
                return true;
            }

            if (showLocalRegion && !showGlobalRegion) {
                if (localRegionList.includes(race.Location.toLowerCase())) {
                    return true;
                }
            }

            if (!showLocalRegion && showGlobalRegion) {
                if (!localRegionList.includes(race.Location.toLowerCase())) {
                    return true;
                }
            }

            return false;
        });

        return displayRaceData;
    };

    const handleRaceClickEvent = async (event) => {
        try {
            setIsLoading(true);
            if (
                event.target.getAttribute("data-value") &&
                event.target.getAttribute("data-value") !== ""
            ) {
                const raceLink = `${
                    config.racesUrl
                }/${event.target.getAttribute(
                    "data-race-link"
                )}/${event.target.getAttribute("data-race-number")}`;
                const response = await fetch(raceLink);
                const fRData = await response.json();
                setFetchedRaceData(fRData);
                toggleModal();
            }

            setIsLoading(false);
        } catch (e) {
            alert("Error Fetching Data");
            setIsLoading(false);
        }
    };

    const handleRaceTypeChange = (e) => {
        setRaceType(e.target.value);
        setRaceData({
            ...raceData,
            display: filterRaceData(
                e.target.value,
                localRegion,
                globalRegion,
                raceData.original
            ),
        });
    };

    const handleLocalRegionChange = (e) => {
        setLocalRegion(e.target.checked);
        setRaceData({
            ...raceData,
            display: filterRaceData(
                raceType,
                e.target.checked,
                globalRegion,
                raceData.original
            ),
        });
    };

    const handleGlobalRegionChange = (e) => {
        setGlobalRegion(e.target.checked);
        setRaceData({
            ...raceData,
            display: filterRaceData(
                raceType,
                localRegion,
                e.target.checked,
                raceData.original
            ),
        });
    };

    const handleBetTypeChange = (e) => {
        setBetType(e.target.value);
    };

    const handleOddType = (e) => {
        setIsFixedOdds(e.target.checked);
    };

    const fetchRaceData = async () => {
        setIsLoading(true);

        try {
            const response = await fetch(
                `${config.racesUrl}/${getCurrentDate()}`
            );
            const data = await response.json();

            const racesData = data.meetings.map((item) => ({
                ...item,
            }));

            const raceDataShaped = racesData.map((race) => {
                let raceId = 0;

                const raceArray = race.races.reduce((acc, element) => {
                    raceId++;
                    let dateObject = new Date(element.raceStartTime);
                    let timeOnly = dateObject.toLocaleTimeString("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                        hour12: false, // Use 24-hour format
                    });

                    acc["Race" + raceId] = [timeOnly, element.raceStatus];
                    return acc;
                }, {});
                return {
                    Venue: `${race.meetingName} (${race.location})`,
                    ...raceArray,
                    Result: "Open",
                    Location: race.location,
                    RaceType: race.raceType,
                    UrlLink: `${race.meetingDate}/meetings/${race.raceType}/${race.venueMnemonic}/races`,
                };
            });

            setRaceData({
                original: raceDataShaped,
                display: filterRaceData(
                    raceType,
                    localRegion,
                    globalRegion,
                    raceDataShaped
                ),
            });
        } catch (error) {
            console.error("Error fetching rated data:", error);
        }

        setIsLoading(false);
    };

    const fetchToken = async () => {
        setIsLoading(true);

        try {
            const fetchedToken = await getValidToken(); // Get the valid token
            setToken(fetchedToken); // Set the token state
        } catch (error) {
            console.error("Error fetching token:", error);
        }
        setIsLoading(false);
    };

    const transformData = (rawData) => {
        const groupedData = rawData.reduce((acc, item) => {
            const key = `${item.Date}-${item.Venue}-${item.Race}`;
            if (!acc[key]) {
                acc[key] = {
                    Date: item.Date,
                    Venue: item.Venue,
                    Race: item.Race,
                    Time: item.Time,
                    NumberOfRunners: item.NumberOfRunners,
                    Distance: item.Distance,
                    Maiden: item.Maiden,
                    tips: [],
                };
            }
            acc[key].tips.push({
                TipNo: item.TipNo,
                Tip: item.Tip,
                Name: item.Name,
                Result: item.Result,
                Rating: item.Rating,
                Win: item.Win,
                Place: item.Place,
                Jockey: item.Jockey,
                Trainer: item.Trainer.replace(/&Amp;/g, "&"), // Replace &Amp; with &
                Barrier: item.Barrier,
            });

            return acc;
        }, {});

        return Object.values(groupedData).map((item) => {
            const results = item.tips.map((tip) => tip.Result);
            const hasFirst = results.includes("1st");
            const hasSecond = results.includes("2nd");
            const hasThird = results.includes("3rd");
            const hasFourth = results.includes("4th");
            const hasOpen = results.includes("Open");
            const hasLose = results.includes("Lose");
            const abandoned = results.includes("Abnd");

            let result = "";
            if (hasFirst) result = "1st";
            else if (hasSecond) result = "2nd";
            else if (hasThird) result = "3rd";
            else if (hasFourth) result = "4th";
            else if (hasOpen) result = "Open";
            else if (hasLose) result = "Lose";
            else if (abandoned) result = "Abandoned";

            if (hasFirst && hasSecond && hasThird && hasFourth) {
                result += ", First 4";
            } else if (hasFirst && hasSecond && hasThird) {
                result += ", Trifecta";
            } else if (hasFirst && hasSecond) {
                result += ", Exacta";
            }

            return { ...item, Result: result };
        });
    };

    useEffect(() => {
        initGA(); // Initialize Google Analytics
        logPageView(window.location.pathname); // Log the page view
        setIsLoading(false);

        fetchToken(); // Call the fetchToken function
        fetchRaceData();
    }, []);

    useEffect(() => {
        if (token) {
            const fetchFirst4Data = async () => {
                try {
                    setIsLoading(true);
                    const currentDate = new Date()
                        .toLocaleDateString("en-CA")
                        .replace(/-/g, ""); // Get current date in YYYYMMDD format
                    console.log("Getting data for:", currentDate);
                    const response = await fetch(
                        `${config.membersUrl}/R/${currentDate}/first4`,
                        {
                            headers: {
                                authorization: `${token}`, // Assuming Session contains the JWT token
                            },
                        }
                    );
                    const rawData = await response.json();
                    setIsLoading(false);

                    if (rawData && Array.isArray(rawData)) {
                        // Transform the flat data into a nested structure
                        const transformedData = transformData(rawData);
                        setFirst4Data(transformedData);
                    } else {
                        console.log("No data found");
                        setFirst4Data([]);
                    }
                } catch (error) {
                    console.error("Error fetching race data:", error);
                    setFirst4Data([]);
                }
            };

            fetchFirst4Data();
        } else {
            setFirst4Data([]);
        }
    }, [token]);

    const columns = React.useMemo(
        () => [
            {
                Header: "Url Link",
                accessor: "UrlLink",
                Class: "card-hidden",
            },
            {
                Header: "Venue",
                accessor: "Venue",
                Class: "card-venue",
            },
            {
                Header: "Result",
                accessor: "Result",
                Class: "card-hidden",
            },
            {
                Header: "Race 1",
                accessor: "Race1",
                Class: "card-details card-tool-race",
                Cell: ({ value }) =>
                    value && value.length > 0 ? value[0] : "",
            },
            {
                Header: "Race 2",
                accessor: "Race2",
                Class: "card-details card-tool-race",
                Cell: ({ value }) =>
                    value && value.length > 0 ? value[0] : "",
            },
            {
                Header: "Race 3",
                accessor: "Race3",
                Class: "card-details card-tool-race",
                Cell: ({ value }) =>
                    value && value.length > 0 ? value[0] : "",
            },
            {
                Header: "Race 4",
                accessor: "Race4",
                Class: "card-details card-tool-race",
                Cell: ({ value }) =>
                    value && value.length > 0 ? value[0] : "",
            },
            {
                Header: "Race 5",
                accessor: "Race5",
                Class: "card-details card-tool-race",
                Cell: ({ value }) =>
                    value && value.length > 0 ? value[0] : "",
            },
            {
                Header: "Race 6",
                accessor: "Race6",
                Class: "card-details card-tool-race",
                Cell: ({ value }) =>
                    value && value.length > 0 ? value[0] : "",
            },
            {
                Header: "Race 7",
                accessor: "Race7",
                Class: "card-details card-tool-race",
                Cell: ({ value }) =>
                    value && value.length > 0 ? value[0] : "",
            },
            {
                Header: "Race 8",
                accessor: "Race8",
                Class: "card-details card-tool-race",
                Cell: ({ value }) =>
                    value && value.length > 0 ? value[0] : "",
            },
            {
                Header: "Race 9",
                accessor: "Race9",
                Class: "card-details card-tool-race",
                Cell: ({ value }) =>
                    value && value.length > 0 ? value[0] : "",
            },
            {
                Header: "Race 10",
                accessor: "Race10",
                Class: "card-details card-tool-race",
                Cell: ({ value }) =>
                    value && value.length > 0 ? value[0] : "",
            },
            {
                Header: "Race 11",
                accessor: "Race11",
                Class: "card-details card-tool-race",
                Cell: ({ value }) =>
                    value && value.length > 0 ? value[0] : "",
            },
            {
                Header: "Race 12",
                accessor: "Race12",
                Class: "card-details card-tool-race",
                Cell: ({ value }) =>
                    value && value.length > 0 ? value[0] : "",
            },
        ],
        []
    );

    return (
        <div className="container">
            {openRaceDetails ? (
                <RaceModal
                    toggleModal={toggleModal}
                    fetchedRaceData={fetchedRaceData}
                    isFixedOdds={isFixedOdds}
                    betType={betType}
                    first4Data={first4Data}
                />
            ) : (
                ""
            )}
            {isLoading ? (
                <Loading />
            ) : (
                <section
                    className="ctext section-body"
                    id="header1-0"
                    data-rv-view="0"
                >
                    <div className="filter-container">
                        <h1 className="table-header"></h1>
                        <div className="filter-section center">
                            <div className="filter-group tools">
                                <div className="filter-radio-group">
                                    <div className="filter-radio">
                                        <input
                                            type="radio"
                                            id="race-thoroughbred"
                                            name="race-type"
                                            value="R"
                                            checked={raceType === "R"}
                                            onChange={handleRaceTypeChange}
                                        />
                                        <label
                                            htmlFor="race-type"
                                            className="switch-label"
                                        >
                                            Thoroughbred
                                        </label>
                                    </div>
                                    <div className="filter-radio">
                                        <input
                                            type="radio"
                                            id="race-greyhound"
                                            name="race-type"
                                            value="G"
                                            checked={raceType === "G"}
                                            onChange={handleRaceTypeChange}
                                        />
                                        <label
                                            htmlFor="race-type"
                                            className="switch-label"
                                        >
                                            Greyhound
                                        </label>
                                    </div>
                                    <div className="filter-radio">
                                        <input
                                            type="radio"
                                            id="race-harness"
                                            name="race-type"
                                            value="H"
                                            checked={raceType === "H"}
                                            onChange={handleRaceTypeChange}
                                        />
                                        <label
                                            htmlFor="race-type"
                                            className="switch-label"
                                        >
                                            Harness
                                        </label>
                                    </div>
                                </div>
                                <div className="filter-checkbox-group">
                                    <div className="filter-checkbox">
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                checked={localRegion}
                                                onChange={
                                                    handleLocalRegionChange
                                                }
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                        <label className="switch-label valued">
                                            AU/NZ
                                        </label>
                                    </div>
                                    <div className="filter-checkbox">
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                checked={globalRegion}
                                                onChange={
                                                    handleGlobalRegionChange
                                                }
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                        <label className="switch-label rated">
                                            International
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <select
                                className="filter-dropdown"
                                onChange={handleBetTypeChange}
                                value={betType}
                            >
                                <option value="D">Dutch</option>
                                <option value="E">Each Way</option>
                            </select>
                            <div className="filter-checkbox">
                                <label className="tab-switch">
                                    <input
                                        type="checkbox"
                                        checked={isFixedOdds}
                                        onChange={handleOddType}
                                    />
                                    <span className="tab-slider round">
                                        <div>Fixed ODDS</div>
                                        <div>TOTE</div>
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="mbr-table-cell align-content-center ctext">
                        <div className="container">
                            <DataTable
                                data={raceData.display}
                                columns={columns}
                                handleRaceClickEvent={handleRaceClickEvent}
                                extraTableClass="tools"
                            />
                        </div>
                    </div>

                    <div id="error-message"></div>
                    <div className="modal"></div>
                </section>
            )}
        </div>
    );
}

export default Tools;
