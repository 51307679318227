import React, { createContext, useState, useEffect, useContext } from 'react';
import { Auth, Cache } from 'aws-amplify';
import jwtDecode from 'jwt-decode';


export const AuthContext = createContext();

export const isTokenExpired = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decodedToken.exp < currentTime;
  } catch (error) {
    console.error('Error decoding token:', error);
    return false;
  }
};

export const refreshToken = async () => {
  try {
    console.log('refreshing token - ac');
    const session = await Auth.currentSession({bypassCache: true});
   // const session = await Auth.currentAuthenticatedUser({bypassCache: true})
    const newToken = session.getIdToken().getJwtToken();
    console.log('new token - ac',newToken);
    // Update the local storage with the new token
    localStorage.setItem('jwtToken', newToken);

    // Update the groups in local storage
    //const user =  await Auth.currentAuthenticatedUser({bypassCache: true});

        // Returns an array of groups
    //const groups = await user.signInUserSession.accessToken.payload["cognito:groups"];
    //console.log('groups ac34: ',groups);

    const decodedToken = await jwtDecode(newToken);
    const groups = await decodedToken['cognito:groups'] || [];
    console.log('saving userGroups: ',JSON.stringify(groups));
    if(groups){
      localStorage.setItem('userGroups', JSON.stringify(groups));
    }

    return newToken;
  } catch (error) {
    console.error('Error refreshing token:', error);
    // Handle the error appropriately here
  }
};

// You can create a function to get a valid token, refreshing if necessary
export const getValidToken = async () => {
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();

  if (isTokenExpired(token)) {
    return refreshToken();
  }

  return token;
};

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(() => {
    // Get the initial state from local storage
    return JSON.parse(localStorage.getItem('currentUser'));
  });

  const [userGroups, setUserGroups] = useState(() => {
    // Get the initial state from local storage
    return JSON.parse(localStorage.getItem('userGroups')) || [];
  });

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setCurrentUser(user);

        // Store the JWT token in local storage
        const federatedInfo = Cache.getItem('federatedInfo');
        if (federatedInfo) {
          const { token } = federatedInfo;
          localStorage.setItem('jwtToken', token);
        }
      } catch (error) {
        console.error('No user signed in', error);
        // Clear everything if the user is not logged in
        await Auth.signOut();
        localStorage.removeItem('jwtToken');
        localStorage.removeItem('currentUser');
        localStorage.removeItem('userGroups');
        setCurrentUser(null);
        setUserGroups([]);

        // Redirect to the login screen
        //window.location.href = "/signin";
      }
    };

    fetchCurrentUser();
  }, []);


  const setAndPersistCurrentUser = (user) => {
    setCurrentUser(user);
    localStorage.setItem('currentUser', JSON.stringify(user));
  };

  const setAndPersistUserGroups = (groups) => {
    setUserGroups(groups);
    localStorage.setItem('userGroups', JSON.stringify(groups));
  };

  return (
    <AuthContext.Provider value={{ currentUser, setCurrentUser: setAndPersistCurrentUser, userGroups, setUserGroups: setAndPersistUserGroups }}>  
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
