import React, { useState, useEffect, useRef } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { initGA, logPageView } from "../utils/gtag";
import moment from "moment-timezone";
import config from "../config/config";
import "../assets/css/Next.css";
import Loading from "./Loading";
import PullToRefresh from 'react-pull-to-refresh';
import useDocumentTitle from "../hooks/useDocumentTitle";
import { Helmet } from "react-helmet";

const stripePromise = loadStripe(config.stripeToken);

const Next = () => {
    const [data, setData] = useState({});
    const [noMoreRaces, setNoMoreRaces] = useState(false);
    const intervalsRef = useRef([]);
    useDocumentTitle('Next To Go');
    const [isLoading, setIsLoading] = useState(true);

    const clearIntervals = () => {
        intervalsRef.current.forEach(clearTimeout);
        intervalsRef.current = [];
        console.log(`[${new Date().toISOString()}] Cleared existing intervals`);
    };

    const fetchData = async () => {
        console.log(`[${new Date().toISOString()}] Fetching data...`);
        setIsLoading(true);
        const sydneyDate = moment().tz("Australia/Sydney").format("YYYYMMDD");
        const nexttogoUrl = `https://data.rippaday.com/v1/open/R/${sydneyDate}/next`;
        console.log(`[${new Date().toISOString()}] Fetching from URL: ${nexttogoUrl}`);

        const response = await fetch(nexttogoUrl);
        const result = await response.json();
        console.log(`[${new Date().toISOString()}] Data fetched:`, result);
        setIsLoading(false);
        if (result.length > 0) {
            console.log(`[${new Date().toISOString()}] Data has elements, setting data and scheduling refresh`);
            setData(result[0]);
            setNoMoreRaces(false);
            scheduleRefreshes(result[0].time);
        } else {
            console.log(`[${new Date().toISOString()}] No data available`);
            setNoMoreRaces(true);
            scheduleNextFetchAt10amTomorrow();
        }
    };

    const scheduleRefreshes = (eventTime) => {
        console.log(`[${new Date().toISOString()}] Scheduling refreshes for event time: ${eventTime}`);
        const currentTime = moment().tz("Australia/Sydney");

        // Calculate event time for today in Sydney time
        let eventMoment = moment.tz(`${currentTime.format('YYYY-MM-DD')} ${eventTime}`, "YYYY-MM-DD h:mm A", "Australia/Sydney");
        console.log(`[${new Date().toISOString()}] Event moment (Sydney time): ${eventMoment.format()}`);
        console.log(`[${new Date().toISOString()}] Current time (Sydney time): ${currentTime.format()}`);

        const timeDiff = eventMoment.diff(currentTime, "minutes");
        console.log(`[${new Date().toISOString()}] Time difference: ${timeDiff} minutes`);

        let newRefreshTimes = [];

        if (timeDiff >= 5) {
            newRefreshTimes.push(eventMoment.clone().subtract(5, "minutes"));
        } else if (timeDiff < 5 && timeDiff >= 1) {
            newRefreshTimes.push(eventMoment.clone().subtract(1, "minute"));
        } else if (timeDiff < 1) {
            newRefreshTimes.push(eventMoment.clone().add(1, "minute"));
        }

        console.log(`[${new Date().toISOString()}] New refresh times calculated:`, newRefreshTimes.map((time) => time.format()));

        window.getNextRefreshTimes = () => newRefreshTimes.map((time) => time.format("YYYY-MM-DD HH:mm:ss"));

        clearIntervals();
        newRefreshTimes.forEach((time) => {
            const delay = time.diff(currentTime);
            if (delay > 0) {
                console.log(`[${new Date().toISOString()}] Scheduling refresh to run at ${time.format("YYYY-MM-DD HH:mm:ss")} which is in ${delay / 1000} seconds`);
                const interval = setTimeout(() => {
                    console.log(`[${new Date().toISOString()}] Refreshing at: ${time.format()}`);
                    fetchData();
                }, delay);
                intervalsRef.current.push(interval);
            } else {
                console.log(`[${new Date().toISOString()}] Skipping past time: ${time.format("YYYY-MM-DD HH:mm:ss")}`);
            }
        });

        console.log(`[${new Date().toISOString()}] Refresh times updated:`, newRefreshTimes.map((time) => time.format()));
    };

    const scheduleNextFetchAt10amTomorrow = () => {
        const tomorrow10am = moment.tz("Australia/Sydney").add(1, "day").set({ hour: 10, minute: 0, second: 0, millisecond: 0 });
        const currentTime = moment().tz("Australia/Sydney");
        const delay = tomorrow10am.diff(currentTime);

        console.log(`[${new Date().toISOString()}] No races left today, scheduling next fetch at 10am tomorrow: ${tomorrow10am.format("YYYY-MM-DD HH:mm:ss")} which is in ${delay / 1000} seconds`);

        clearIntervals();
        const interval = setTimeout(() => {
            console.log(`[${new Date().toISOString()}] Fetching data at 10am tomorrow: ${tomorrow10am.format("YYYY-MM-DD HH:mm:ss")}`);
            fetchData();
        }, delay);
        intervalsRef.current.push(interval);
    };

    useEffect(() => {
        initGA();
        logPageView(window.location.pathname);
        console.log(`[${new Date().toISOString()}] Running initial fetchData`);
        fetchData();

        return () => {
            clearIntervals();
        };
    }, []);

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat("en-AU", {
            style: "currency",
            currency: "AUD",
        }).format(amount);
    };

    const handleButtonClick = async (event) => {
        const stripe = await stripePromise;
        const mode = event.target.dataset.checkoutMode;
        const priceId = event.target.dataset.priceId;
        const items = [{ price: priceId, quantity: 1 }];
        const DOMAIN = window.location.href.replace(/[^/]*$/, "");

        stripe
            .redirectToCheckout({
                mode,
                lineItems: items,
                successUrl: `${DOMAIN}success?session_id={CHECKOUT_SESSION_ID}`,
                cancelUrl: `${DOMAIN}index.html?session_id={CHECKOUT_SESSION_ID}`,
            })
            .then((result) => {
                if (result.error) {
                    const displayError = document.getElementById("error-message");
                    displayError.textContent = result.error.message;
                }
            });
    };

    const handleRefresh = async () => {
        console.log(`[${new Date().toISOString()}] Handling pull-to-refresh`);
        await fetchData();
    };

    return (
        <div className="container">
            {isLoading ? (
                <Loading />
            ) : (
        <div className="nextContainer">
            
            <div className="nextForm">
                <PullToRefresh onRefresh={handleRefresh}>
                    <section className="next-options">
                    <Helmet>
                        <meta name="description" content="Next To Go - 4 tips per race" />
                        <meta name="keywords" content="Next to go, next horse race, top 4 tips, horse racing, free tips for venues including Eagle Farm, Caulfield, Flemington, Moonee Valley, Rosehill and Randwick" />
                    </Helmet>
                        <div>
                            {!noMoreRaces && (
                                <h3>
                                    <strong id="venue">{data.venue}</strong>
                                    <strong> Race </strong>
                                    <strong id="race">{data.raceNumber}</strong> - <strong id="time">{data.time}</strong>
                                </h3>
                            )}
                            {!noMoreRaces && (
                                <h3>
                                    <text id="distance">{data.distance}</text>
                                    <text>m - </text>
                                    <text id="runners">{data.runners}</text> runners
                                </h3>
                            )}
                        </div>
                        <br />
                        <div className="subHeader totdText" align="center">
                            {noMoreRaces ? (
                                <h4 className="totdText" align="center">
                                    <strong>No more races today, check back tomorrow</strong>
                                </h4>
                            ) : (
                                data.tips &&
                                data.tips.map((tip, index) => (
                                    <h4 key={index} className="totdText" align="center">
                                        <strong>
                                            <text>{tip.horse}</text>
                                            <text> - </text>
                                            <text>{tip.horseName}</text>
                                            <text> - </text>
                                            <text>{formatCurrency(tip.win)}</text>
                                            <text> / </text>
                                            <text>{formatCurrency(tip.place)}</text>
                                        </strong>
                                    </h4>
                                ))
                            )}
                        </div>
                        <div id="error-message"></div>
                    </section>
                </PullToRefresh>      
            </div>
        
        </div>
        )}
        </div>
    );
};

export default Next;
