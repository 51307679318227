import { useTable } from "react-table";

function DataTable({
    data,
    columns,
    handleRaceClickEvent = () => {},
    extraTableClass = "",
}) {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable({ columns, data });

    const handleRowExpand = (event) => {
        const div = event.currentTarget;
        if (div.classList.contains("expanded")) {
            div.classList.remove("expanded");
        } else {
            div.classList.add("expanded");
        }
    };

    return (
        <table {...getTableProps()} className={`myTabs ${extraTableClass}`}>
            <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th
                                {...column.getHeaderProps()}
                                className={column.Class ?? ""}
                            >
                                {column.render("Header")}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                    prepareRow(row);

                    let resultClass = "";
                    let raceLink = "";
                    if (row.original.Result === "Open") {
                        resultClass = "open";
                    } else if (row.original.Result === "1st") {
                        resultClass = "win";
                    } else if (
                        row.original.Result === "2nd" ||
                        row.original.Result === "3rd"
                    ) {
                        resultClass = "place";
                    } else if (row.original.Result === "Abnd") {
                        resultClass = "abnd";
                    } else if (row.original.Result === "Scr") {
                        resultClass = "scr";
                    } else {
                        resultClass = "lose";
                    }
                    return (
                        <tr
                            {...row.getRowProps()}
                            className={resultClass}
                            onClick={handleRowExpand}
                        >
                            {row.cells.map((cell) => {
                                const raceType = [];
                                const cellValue = cell.value;

                                if (cell.column.Header === "Url Link") {
                                    raceLink = cell.value;
                                }
                                if (cell.column.Header === "Type") {
                                    if (
                                        cellValue &&
                                        cellValue.includes("Rated")
                                    ) {
                                        raceType.push({
                                            Type: "Rated",
                                            ClassName: "card-rated",
                                        });
                                    }

                                    if (
                                        cellValue &&
                                        cellValue.includes("Value")
                                    ) {
                                        raceType.push({
                                            Type: "Value",
                                            ClassName: "card-value",
                                        });
                                    }

                                    if (
                                        cellValue &&
                                        cellValue.includes("Slag")
                                    ) {
                                        raceType.push({
                                            Type: "Slag",
                                            ClassName: "card-2way",
                                        });
                                    }

                                    if (
                                        cellValue &&
                                        cellValue.includes("Best")
                                    ) {
                                        raceType.push({
                                            Type: "Best",
                                            ClassName: "card-best",
                                        });
                                    }
                                }

                                return (
                                    <td
                                        {...cell.getCellProps()}
                                        data-label={cell.column.Header}
                                        data-race-link={raceLink}
                                        data-value={
                                            cell.value && cell.value[0]
                                                ? cell.value[0]
                                                : cell.value
                                        }
                                        data-race-number={
                                            cell.column.Header.startsWith(
                                                "Race "
                                            )
                                                ? cell.column.Header.replace(
                                                      "Race ",
                                                      ""
                                                  )
                                                : ""
                                        }
                                        className={
                                            cell.value &&
                                            cell.value[1] &&
                                            cell.column.Header.startsWith(
                                                "Race"
                                            ) &&
                                            (cell.value[1] === "Paying" ||
                                                cell.value[0] === "" ||
                                                !cell.value)
                                                ? "race-disabled card-details"
                                                : cell.value !== undefined
                                                ? cell.column.Class ??
                                                  "card-details"
                                                : "race-disabled"
                                        }
                                        onClick={
                                            cell.column.Header.startsWith(
                                                "Race "
                                            )
                                                ? (e) => {
                                                      handleRaceClickEvent(
                                                          e,
                                                          cell.value
                                                      );
                                                  }
                                                : () => {}
                                        }
                                    >
                                        {raceType.map((race, index) => (
                                            <div
                                                key={index}
                                                className={
                                                    cell.value === undefined
                                                        ? "card-hidden"
                                                        : race.ClassName
                                                }
                                            >
                                                {race.Type}
                                            </div>
                                        ))}

                                        {cell.column.Header !== "Type"
                                            ? cell.render("Cell")
                                            : ""}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}

export default DataTable;
