import React, { useEffect, useState } from 'react';
import "../assets/css/Subscription.css";

function Success() {
  const [sessionId, setSessionId] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('session_id')) {
      setSessionId(urlParams.get('session_id'));
    }

    // Setting the height and width dynamically (as per your script)
    const height = window.innerHeight;
    const width = window.innerWidth;
    console.log('Height:', height, 'Width:', width);
  }, []);

  return (
    
    <section className="subscriptionContainer"  >
        <br></br><br></br><br></br>
      <div className="successForm">
            <h2 >#RippaDay</h2>
            <h2 >Thanks for your support!</h2>
              
              <h4 className="totdText">
                Receipt Reference: <span id="session">{sessionId}</span>
              </h4>
      </div>
      <div className="modal">
        {/* Place at bottom of page */}
      </div>
    </section>
  );
}

export default Success;
