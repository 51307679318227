import React, { useState, useEffect } from "react";
import { useAuth, getValidToken } from "./auth/AuthContext";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom"; // Import useNavigate instead of useHistory
import "../assets/css/Subscription.css";
import config from "../config/config";
import { initGA, logPageView } from "../utils/gtag"; // Import the functions
import useDocumentTitle from "../hooks/useDocumentTitle"; // Import the custom hook

const stripePromise = loadStripe(config.stripeToken);

function Subscription() {
    useDocumentTitle('RippaDay - Sign Up'); // Set the page title
    const [selectedPriceId, setSelectedPriceId] = useState("price_1POCcHHuikSJIOpoEDDKLOuK"); // Default to weekly price ID
    const [prices, setPrices] = useState([]);
    const navigate = useNavigate(); // Get the navigate function using useNavigate
    const [token, setToken] = useState(null); // Initialize token state

    useEffect(() => {
        initGA(); // Initialize Google Analytics
        logPageView(window.location.pathname); // Log the page view
        const fetchToken = async () => {
            try {
                const fetchedToken = await getValidToken(); // Get the valid token
                setToken(fetchedToken); // Set the token state
            } catch (error) {
                console.error("Error fetching token:", error);
            }
        };

        fetchToken(); // Call the fetchToken function
    }, []);

    useEffect(() => {
        const fetchPrices = async () => {
            try {
                const response = await fetch(`${config.subscribeUrl}/products`);
                const data = await response.json();
                setPrices(data.prices);
            } catch (error) {
                console.error("Error fetching prices", error);
            }
        };

        fetchPrices();
    }, []);

    const handleCheckout = async () => {
        try {
            const stripe = await stripePromise;

            const response = await fetch(`${config.subscribeUrl}/pay`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    authorization: `${token}`,
                },
                body: JSON.stringify({ priceId: selectedPriceId }),
            });

            const session = await response.json();
            const result = await stripe.redirectToCheckout({
                sessionId: session.id,
            });

            if (result.error) {
                alert(result.error.message);
            }
        } catch (error) {
            console.error("Error initiating Stripe checkout", error);
        }
    };

    const navigateToSignIn = () => {
        // Create a function to navigate to the sign-in route
        navigate("/members"); // Use navigate function to navigate to the sign-in route
    };

    return (
        <div className="subscriptionContainer">
            <div className="subscriptionForm">
            <h2>Select Your Subscription Plan</h2>
            <div className="subscription-options">
                {prices
                    .sort((a, b) => a.unit_amount - b.unit_amount)
                    .map((price) => (
                        <label key={price.id}>
                            <input
                                type="radio"
                                name="plan"
                                value={price.id}
                                checked={
                                    selectedPriceId === price.id
                                }
                                onChange={(e) => setSelectedPriceId(e.target.value)}
                            />
                            {`$${(price.unit_amount / 100).toFixed(2)} a ${
                                price.recurring.interval
                            }`}
                        </label>
                    ))}
            </div>
            <h2>7 day free trial on all new subscriptions</h2>
            <button className="action-button" onClick={handleCheckout}>
                Start Trial
            </button>
            <br></br>
            <p>Subscriptions can be altered or cancelled at any time by clicking on your email or manage within the app</p>
            </div>
        </div>
    );
}

export default Subscription;
