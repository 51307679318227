import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

function ProtectedRoute({ component: Component, ...rest }) {
  const { currentUser, userGroups } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) {
      navigate('/signin');
    } else if (!(userGroups.includes('activeSubscription') || userGroups.includes('lifeMember'))) {
      navigate('/subscription'); // or some other route to indicate the user is not authorized
    }
  }, [currentUser, userGroups, navigate]);

  if (!currentUser || !userGroups.includes('activeSubscription')) {
    return null;
  }

  return <Component {...rest} />;
}

//export default ProtectedRoute;
export default React.memo(ProtectedRoute);